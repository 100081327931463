import { useState, useEffect } from 'react'; // Custom hook to get and update window dimensions and scroll position
const useWindowDimensions = () => {
    const { 0: dims, 1: setDims } = useState({ x: 1200, y: 1200 });
    const { 0: yScroll, 1: setYScroll } = useState(0);
    const { 0: height, 1: setHeight } = useState();
    useEffect(() => {
        const updateDims = () => setDims({ x: window.innerWidth, y: window.innerHeight });
        const updateScroll = () => setYScroll(window.pageYOffset);
        window.addEventListener('resize', updateDims);
        window.addEventListener('scroll', updateScroll); // Update dimensions and scroll position when the component mounts
        updateDims();
        updateScroll();
        setHeight(window.document.body.scrollHeight);
        return () => { window.removeEventListener('resize', updateDims); window.removeEventListener('scroll', updateScroll); };
    }, []);
    return { dims, yScroll, height };
};
export default useWindowDimensions;
